import React from 'react'

import { block } from 'utils/classnames'
import href from 'config/hrefs'

import { getDateYear } from 'utils/datetime'

import './Legal.sass'

const b = block.with('legal')

export const Legal = props => {
  return (
    <section className={b()}>
      <p>
        © Foodchéri {getDateYear()}. Cuisiné avec <i className='heart'>❤</i> à Sucy
      </p>
      <p>
        <a className={b('link')} target='_blank' rel='noopener noreferrer' href={href.rootPrivacyPolicy}>
          Politique de confidentialité
        </a>
        &nbsp;&mdash;&nbsp;
        <a className={b('link')} target='_blank' rel='noopener noreferrer' href={href.rootPrivacyPolicy}>
          Protection des données
        </a>
        &nbsp;&mdash;&nbsp;
        <span className={b('link')} onClick={() => window.openAxeptioCookies()}>
          Cookies
        </span>
        <br />
        <a className={b('link')} target='_blank' rel='noopener noreferrer' href={href.legalNoticePage}>
          Mentions légales
        </a>
        &nbsp;&mdash;&nbsp;
        <a className={b('link')} target='_blank' rel='noopener noreferrer' href={href.generalSaleConditionPage}>
          CGV
        </a>
      </p>
    </section>
  )
}

export default Legal
