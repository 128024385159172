import React from 'react'

import { block } from 'utils/classnames'
import href from 'config/hrefs'

import './Commitment.sass'

export const configPicto = [
  {
    id: 'homemade',
    label: 'Cuisiné \n par des chef.fes',
    icon: 'homemade'
  },
  {
    id: 'nutriscore',
    label: '91% de nos plats \n en Nutri-Score A et B',
    icon: 'nutriscore'
  },
  {
    id: 'ecoscore',
    label: '96% de nos plats \n en Eco-Score A et B',
    icon: 'ecoscore'
  },
  {
    id: 'packaging',
    label: 'Packaging \n 100% eco-friendly',
    icon: 'packaging'
  },
  {
    id: 'cereal',
    label: 'Céréales \n bio',
    icon: 'cereal'
  },
  {
    id: 'fruits',
    label: 'Fruits et légumes \n de saison',
    icon: 'fruits'
  },
  {
    id: 'eggs',
    label: "100% d'œufs \n plein air",
    icon: 'eggs'
  },
  {
    id: 'fish',
    label: 'Pêche responsable et \n viande labellisée',
    icon: 'fish'
  }
]

export const Picto = (props) => {
  const {
    label,
    icon
  } = props
  const isEcoscore = icon === 'ecoscore'
  return (
    <li
      className={block('commitment', 'item')}
    >
      <span
        className={block('commitment', 'icon', { isEcoscore })}
        style={{
          backgroundImage: `url(/images/commitment/${icon}.svg)`
        }}
      />
      {
        label.split('\n')
          .map(row => (
            <span
              key={row}
              className={block('commitment', 'label')}
            >
              {row}
            </span>
          ))
      }
    </li>
  )
}

export const Commitment = props => {
  return (
    <section className={block('commitment')}>
      <div className={block('commitment', 'container')}>
        <h2 className={block('commitment', 'title')}>
          Pour vous, on s'engage...
        </h2>
        <ul className={block('commitment', 'list')}>
          {
            configPicto
              .map(row => (
                <Picto
                  key={row.id}
                  {...row}
                />
              ))
          }
        </ul>
        <h2 className={block('commitment', 'title')}>
          <span>...hier, aujourd'hui et demain !</span>
        </h2>
        <p className={block('commitment', 'text')}>
          Depuis la naissance de FoodChéri en 2015,
          nous nous engageons pour une alimentation responsable.
          Derrière les mots, des actions concrètes.
        </p>
        <a
          href={href.valuePage}
          rel='noopener noreferrer'
          target='_blank'
          className={block('commitment', 'link')}
        >
          Dites m'en plus !
        </a>
      </div>
    </section>
  )
}

export default Commitment
