import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Commitment } from './Commitment'
import { Legal } from './Legal'
import { PaymentMethodIcons } from './PaymentMethodIcons'
import { PressBar } from './PressBar'
import { SectionLink } from './SectionLink'
import { SocialNetwork } from './SocialNetwork'
import { NewsletterForm } from './NewsletterForm'

import { block } from 'utils/classnames'

import { getNewsletterSubscription, getMe } from 'reducers'
import { subscribeToNewsletter } from 'actions/user'

import './Footer.sass'

const b = block.with('footer')

export const ConnectedNewsletterForm = () => {
  const dispatch = useDispatch()
  const state = useSelector(getNewsletterSubscription)
  const user = useSelector(getMe)

  const handleSubmit = useCallback((email) => {
    dispatch && dispatch(subscribeToNewsletter(
      email,
      'footer',
      { last_name: 'NomInconnu' }
    ))
  }, [])

  return user?.name
    ? null
    : (
      <NewsletterForm
        {...state}
        onSubmit={handleSubmit}
      />
    )
}

export const Footer = props => {
  const {
    withCommitment = true,
    withPressBar = true,
    withSectionLink = true,
    withPaymentMethods = true,
    withSocialNetwork = true,
    withLegal = true,
    withNewsletterForm = false
  } = props

  return (
    <footer className={b()}>
      <div className={b('topContent')}>
        {
          withCommitment ? <Commitment /> : null
        }
        {
          withPressBar ? <PressBar /> : null
        }
        <hr />
        {
          withNewsletterForm ? <ConnectedNewsletterForm /> : null
        }
        {
          withSectionLink ? <SectionLink /> : null
        }
        {
          withPaymentMethods ? <PaymentMethodIcons /> : null
        }
        {
          withSocialNetwork ? <SocialNetwork /> : null
        }
      </div>
      {
        withLegal ? <Legal /> : null
      }
    </footer>
  )
}
Footer.propTypes = {
  withCommitment: PropTypes.bool,
  withPressBar: PropTypes.bool,
  withSectionLink: PropTypes.bool,
  withPaymentMethods: PropTypes.bool,
  withSocialNetwork: PropTypes.bool,
  withLegal: PropTypes.bool,
  withNewsletterForm: PropTypes.bool
}
export default Footer
