import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { block } from 'utils/classnames'
import { pushHomepageEvent } from 'utils/googletagmanager'
import href from 'config/hrefs'

import './NewsletterForm.sass'

const b = block.with('newsletter-widget')
export const NewsletterForm = props => {
  const {
    isLoading = false,
    message,
    onSubmit
  } = props

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleChange = useCallback(e => {
    setError('')
    setEmail(e.target.value)
  }, [])

  const handleSubmit = useCallback(e => {
    e.preventDefault()

    if (!email) {
      setError('Entrez votre email pour vous inscrire à la newsletter')

      return
    }
    pushHomepageEvent('SubscribeNewsletter', { email })
    onSubmit && onSubmit(email)
  }, [email, onSubmit])

  return (
    <div className={b()}>
      <h4 className={b('title')}>
        Abonnez-vous à la newsletter svp (on veut pas virer celui qui la rédige)
      </h4>
      <form className={b('form')} onSubmit={handleSubmit}>
        <label htmlFor='email'>
          <input
            type='text'
            autoComplete='email'
            placeholder='barack@maridemichelle.com'
            value={email}
            onChange={handleChange}
            disabled={isLoading}
          />
        </label>
        <button
          type='submit'
          disabled={isLoading}
        >
          Je sauve un emploi
        </button>
      </form>
      {error || message
        ? (<p className={b('message', { error: !!error })}>{error || message}</p>)
        : null}
      <p className={b('message-legal-notice')}>
      En renseignant votre adresse email, vous acceptez de recevoir chaque semaine nos communications par courrier électronique et vous prenez connaissance de notre <a href={href.rootPrivacyPolicy} target='_blank' rel='noopener noreferrer'>politique de confidentialité</a>.
      Vous pouvez vous désinscrire à tout moment depuis votre Espace Client ou via les liens de désinscription.
      </p>
    </div>
  )
}
NewsletterForm.propTypes = {
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  onSubmit: PropTypes.func
}
export default NewsletterForm
