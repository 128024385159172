import moment from 'moment'

import { capitalizeFirst } from 'utils/strings'

moment.locale('fr')

const dateFormats = {
  sameDay: '[Aujourd\'hui]',
  nextDay: '[Demain]',
  nextWeek: 'dddd',
  lastDay: '[Hier]',
  lastWeek: 'dddd [dernier]',
  sameElse: 'dddd D MMM'
}

const NO_START_TEST = /^\d{1,2}:{1}32$/
const DEFAULT_FORMAT = ':start - :end'
const NO_START_FORMAT = 'Avant :end'

export const getDateLabel = (date = Date.now()) => {
  if (!date || !moment(date).isValid()) {
    return date
  }

  const day = moment(date)
  return capitalizeFirst(
    day.calendar(null, {
      sameDay: `[${day.calendar().split(' ').shift()}]`,
      nextDay: `[${day.calendar().split(' ').shift()}]`,
      nextWeek: 'dddd D',
      sameElse: 'dddd D',
      lastDay: 'dddd D',
      lastWeek: 'dddd D'
    })
  )
}

/**
 * Return the formatted timeslot
 * @param {Object} timeslot
 * @param {Boolean} checkStartDate // true: check if the startDate ends with 32, if so only the endDate is returned and it is preceded by 'avant '
 * @returns {String}
 */
/* export const getTimeslotLabel = (timeslot, checkStartDate = false) => {
  if (timeslot && timeslot.deliveryStartDate && timeslot.deliveryEndDate) {
    const formattedStartDate = moment(timeslot.deliveryStartDate).format('HH:mm')
    const formattedEndDate = moment(timeslot.deliveryEndDate).format('HH:mm')

    return checkStartDate && formattedStartDate.endsWith('32') ? 'avant ' + formattedEndDate : formattedStartDate + ' - ' + formattedEndDate
  }
  return ''
} */

export const getTimeslotLabel = ({ start, end, fallback = '', format, withDate = false }) => {
  if (!start && !end && !fallback) {
    return null
  }

  const timeslotStart = start && moment(start).isValid() ? moment(start) : null
  const timeslotEnd = end && moment(end).isValid() ? moment(end) : null

  if (!start || !end) {
    return fallback
  }

  const usedFormat = format || DEFAULT_FORMAT

  const startTime = timeslotStart.format('HH:mm')

  let timeslotString = NO_START_TEST.test(startTime)
    ? NO_START_FORMAT.replace(':end', timeslotEnd.format('H:mm'))
    : usedFormat.replace(':start', startTime).replace(':end', timeslotEnd.format('HH:mm'))

  timeslotString = withDate
    ? `${
            timeslotEnd.calendar(null, dateFormats)
        }, ${
            timeslotString.toLowerCase()
        }`
    : timeslotString

  return timeslotString
}

/**
 * Return the day of the month for a given date
 * @param {String | Date } date
 */
export const getDateDay = (date = Date.now()) => {
  const newMoment = moment(date)
  return +newMoment.format('D')
}

/**
 * Return full month name for a given date
 * @param {Date | String | Moment} date
 * @returns {String}
 */
export const getMonthNameFromDate = (date = Date.now()) => {
  const newMoment = moment(date)
  return newMoment.format('MMMM')
}

/**
 * Return the year for a given date
 * @param {Date | String | Moment} date
 * @returns {String}
 */
export const getDateYear = (date = Date.now()) => {
  const newMoment = moment(date)
  return newMoment.format('YYYY')
}

/**
 * Return the acronym of the month for a given date
 * @param {Date | String | moment} date
 * @returns {String}
 */
export const getDateMonthAcronym = (date = Date.now()) => {
  const newMoment = moment(date)
  return newMoment.format('MMM').substring(0, 3)
}

/**
 * Return full month name and yaear for a given date
 * @param {Date | String | Moment} date
 * @returns {String}
 */
export const getMonthAndYearFromDate = (date = Date.now()) => {
  const newMoment = moment(date)
  return newMoment.format('MMMM YYYY')
}

/**
 * Return formatted date
 * @param {Date | String | Moment} date
 * @param {Boolean} withTime
 * @param {String} separator
 * @returns {String}
 */
export const getFormattedDate = (date = Date.now(), withTime = false, separator) => {
  const newMoment = moment(date)
  let formattedDate = newMoment.format('DD-MM-YYYY HH:mm')
  if (!withTime) {
    formattedDate = formattedDate.substr(0, 10)
  }
  if (separator) {
    const re = new RegExp('-', 'g')
    formattedDate = formattedDate.replace(re, separator)
  }
  return formattedDate
}

/**
 * Return expiration date object
 * @param {Date | String | Moment} date
 * @returns {Object}
 */
export const expiry = (date = Date.now()) => {
  const newMoment = moment(date)
  const difference = newMoment.diff(moment(), 'days')
  return { difference, date: newMoment.format('MM/YYYY') }
}

/**
 * Return the time for a given date
 * @param {Date | String | Moment} date
 * @returns {String}
 */
export const getDateTime = (date = Date.now()) => {
  const newMoment = moment(date)
  return newMoment.format('HH:mm')
}

export const getDatesDiff = (firstDate, secondDate, criteria = 'days') => {
  const usedCriteria = ['years', 'month', 'days', 'hours', 'minutes', 'seconds'].includes(criteria) ? criteria : 'days'
  return Math.round(moment(firstDate).diff(moment(secondDate), usedCriteria, true))
}
