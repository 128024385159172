import React, { useRef } from 'react'

/* Use to block multiple user actions on click */

export const PreventButton = ({ onClick, children, className = '', isForm = false, disabled = false, title = '' }) => {
  const usedRef = useRef(false)
  const handleOnClick = () => {
    if (usedRef.current) return
    usedRef.current = true
    onClick()
  }
  return (
    isForm
      ? <button title={title} type='submit' className={className} disabled={disabled}>{children}</button>
      : <button className={className} disabled={usedRef.current || disabled} onClick={handleOnClick}>{children}</button>
  )
}
