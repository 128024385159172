import React from 'react'
import { block } from 'utils/classnames'

import visaSrc from 'images/payment-methods/visa.svg'
import mastercardSrc from 'images/payment-methods/mastercard.png'
import upSrc from 'images/payment-methods/up.svg'
import swileSrc from 'images/payment-methods/swile.svg'
import apetizSrc from 'images/payment-methods/apetiz.svg'
import edenredSrc from 'images/payment-methods/edenred.png'
import passSrc from 'images/payment-methods/pass.png'
import paypalSrc from 'images/payment-methods/paypal.svg'
import googlepaySrc from 'images/payment-methods/googlepay.svg'
import restoflashSrc from 'images/payment-methods/restoflash.png'
import amexSrc from 'images/payment-methods/amex.png'

import './PaymentMethodIcons.sass'

const b = block.with('accepted-payment-methods')

export const ACCEPTED_PAYMENT_METHODS = [
  {
    name: 'visa',
    alt: 'Visa',
    src: visaSrc
  }, {
    name: 'mastercard',
    alt: 'Mastercard',
    src: mastercardSrc
  }, {
    name: 'amex',
    alt: 'American Express',
    src: amexSrc
  }, {
    name: 'paypal',
    alt: 'PayPal',
    src: paypalSrc
  }, {
    name: 'pass',
    alt: 'Pass restaurant',
    src: passSrc
  }, {
    name: 'edenred',
    alt: 'Edenred',
    src: edenredSrc
  }, {
    name: 'br'
  }, {
    name: 'apetiz',
    alt: 'Apetiz',
    src: apetizSrc
  }, {
    name: 'up',
    alt: 'Up',
    src: upSrc
  }, {
    name: 'restoflash',
    alt: 'RestoFlash',
    src: restoflashSrc
  }, {
    name: 'swile',
    alt: 'Swile',
    src: swileSrc
  }, {
    name: 'googlepay',
    alt: 'Google Pay',
    src: googlepaySrc
  }
]

export const ICONS_KEY = 'icons'
export const AcceptedPaymentMethodIcons = () => {
  return (
    ACCEPTED_PAYMENT_METHODS.map(({ name, alt = '', src }, i) => (
      name === 'br'
        ? <br key={'br' + i} />
        : (
          <img
            key={name}
            src={src}
            alt={alt}
            title={alt}
            className={b('icon', { method: name })}
          />
        )
    )
    )
  )
}

export const CHECKLIST_KEY = 'checklist'
export const AcceptedPaymentMethodChecklist = () => (
  <ul className={b('checklist')}>
    <li>
      Nous ne stockons pas vos données bancaires
    </li>
    <li>
      Paiement sécurisé
    </li>
    <li>
      Annulez en un clic
    </li>
  </ul>
)

export const UNACCEPTED_KEY = 'unaccepted'
export const UnacceptedPaymentMethods = () => (
  <p className={b('unaccepted')}>
    Nous n’acceptons pas les chèques et les espèces. Paiement sécurisé. Annulez en un clic.
  </p>
)

export const Sections = {
  [ICONS_KEY]: AcceptedPaymentMethodIcons,
  [CHECKLIST_KEY]: AcceptedPaymentMethodChecklist,
  [UNACCEPTED_KEY]: UnacceptedPaymentMethods
}

export const PaymentMethodIcons = ({ sections = [ICONS_KEY] }) => {
  return (
    <div className={b()}>
      {
        sections.map(section => {
          const Section = Sections[section]

          return Section
            ? <Section key={section} />
            : null
        })
      }
    </div>
  )
}

export default PaymentMethodIcons
