import React from 'react'

import RouteLink from 'components/Router/RouteLink'

import { block } from 'utils/classnames'
import { getString, KEY } from 'utils/cookies'
import href from 'config/hrefs'

import './SectionLink.sass'

export const LINKS_SECTION = [
  {
    id: 'howItWorks',
    title: 'Comment ça marche ?',
    links: [
      {
        id: 'coveredArea',
        label: 'Zone de livraison',
        href: href.deliveryAreaPage,
        targetBlank: true,
        rel: 'noopener noreferrer'
      },
      {
        id: 'b2bLandingPage',
        label: 'Offre entreprise',
        href: href.b2bLandingPage,
        targetBlank: true,
        rel: 'noopener noreferrer'
      },
      {
        id: 'FAQ',
        label: 'FAQ',
        href: href.supportPage,
        targetBlank: true,
        rel: 'noopener noreferrer'
      }
    ]
  },
  {
    id: 'ourValues',
    title: 'Nos Valeurs',
    links: [
      {
        id: 'engagements',
        label: 'Nos engagements',
        href: href.valuePage,
        targetBlank: true,
        rel: 'noopener noreferrer'
      },
      {
        id: 'blog',
        label: 'Blog',
        href: href.blogHomepage,
        targetBlank: true,
        rel: 'noopener noreferrer'
      }
    ]
  },
  {
    id: 'contact',
    title: 'Contact',
    links: [
      {
        id: 'contactUs',
        label: 'Contactez nous',
        href: href.contactUs,
        rel: 'nofollow noopener noreferrer'
      },
      {
        id: 'press',
        label: 'Presse',
        href: href.pressPage,
        targetBlank: true,
        rel: 'noopener noreferrer'
      },
      {
        id: 'jobs',
        label: 'Jobs',
        href: href.jobPage,
        targetBlank: true,
        rel: 'nofollow noopener noreferrer'
      },
      {
        id: 'deliveryman',
        label: 'Devenir livreur',
        href: href.deliverymanJobPage,
        rel: 'nofollow noopener noreferrer'
      }
    ]
  },
  {
    id: 'download',
    title: 'Téléchargez l\'app',
    links: [
      {
        id: 'link-apple',
        idLink: 'link-app-apple',
        className: 'app-ios',
        href: href.app.iosDownload,
        targetBlank: true
      },
      {
        id: 'link-google',
        idLink: 'link-app-google',
        className: 'app-android',
        href: href.app.androidDownload,
        targetBlank: true
      }
    ]
  }
]

export const Link = (props) => {
  const {
    idLink = '',
    routeId = '',
    label = '',
    href = '',
    className = '',
    targetBlank = false,
    rel = ''
  } = props

  if (!href && !routeId) {
    return null
  }

  return (
    <li className={block('footer-link', 'item')}>
      {
        routeId
          ? (
            <RouteLink routeId={routeId} className={block('footer-link', 'item-link') + ' ' + className}>
              {label}
            </RouteLink>
          )
          : (
            <a
              href={href}
              id={idLink}
              className={block('footer-link', 'item-link') + ' ' + className}
              target={targetBlank ? '_blank' : ''}
              rel={rel}
            >
              {label}
            </a>
          )
      }
    </li>
  )
}

export const SectionLink = props => {
  const isOnMobileApp = !!getString(KEY.webApp)
  return (
    <section className={block('footer-link', 'container')}>
      {
        LINKS_SECTION.filter(({ id }) => id !== 'download' || !isOnMobileApp).map((section, index) => {
          return (
            <div className={block('footer-link', 'column')} key={index}>
              <h4 className={block('footer-link', 'title')}>{section.title}</h4>
              <ul className={block('footer-link', 'list')}>
                {
                  section.links.map(link => (
                    <Link key={link.id} {...link} />
                  ))
                }
              </ul>
            </div>
          )
        })
      }
    </section>
  )
}

export default SectionLink
