import React from 'react'

import { block } from 'utils/classnames'
import href from 'config/hrefs'

import './PressIcons.sass'

export const configPicto = Object
  .entries(href.press)
  .map(([id, href]) => ({
    id, href
  }))

export const Icon = ({ id, href, icon }) => {
  return (
    <li className={block('press', 'item')}>
      <a
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className={block('press', 'link', { id })}
      />
    </li>
  )
}

export const PressBar = props => {
  return (
    <section className={block('press')}>
      <ul className={block('press', 'list')}>
        {
          configPicto.map(press => <Icon key={press.id} {...press} />)
        }
      </ul>
    </section>
  )
}

export default PressBar
