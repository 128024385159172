import React from 'react'

import { block } from 'utils/classnames'
import href from 'config/hrefs'

import './SocialNetwork.sass'

export const SOCIAL_NETWORK = Object
  .entries(href.social)
  .map(([id, href]) => ({
    id,
    href
  }))

export const SocialNetwork = props => {
  return (
    <ul className={block('social-network', 'list')}>
      {
        SOCIAL_NETWORK.map(item => {
          return (
            <li key={item.id} className={block('social-network', 'item')}>
              <a
                href={item.href}
                rel='noopener noreferrer'
                target='_blank'
                aria-label={item.id}
              >
                <i className={block('icon-' + item.id)} />
              </a>
            </li>
          )
        })
      }
    </ul>
  )
}

export default SocialNetwork
